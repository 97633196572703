import Products from './products';

function sendEcommerceEvent(eventName, data) {
    const dataLayerData = {
        event: eventName,
        ecommerce: data,
    };

    console.debug('DataLayer Data: %o', dataLayerData);

    dataLayer.push({ ecommerce: null });
    dataLayer.push(dataLayerData);
}

export default {
    /**
     * Add To Cart
     * GTM Event: add_to_cart
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_cart
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    addToCart(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        const localGTMData = 'gtmData' in data ? data.gtmData : window.gtmData;
        sendEcommerceEvent('add_to_cart', localGTMData);
    },
    /**
     * Remove From Cart
     * GTM Event: remove_from_cart
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#remove_from_cart
     * @param {jQuery.Event|null} e
     * @param {Object} data
    */
    removeFromCart(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        const localGTMData = 'gtmData' in data ? data.gtmData : window.gtmData;
        sendEcommerceEvent('remove_from_cart', localGTMData);
    },
    /**
     * View Cart
     * GTM Event: view_cart
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_cart
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    viewCart(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('view_cart', data);
    },
    updateCheckoutView(e, data) {
        console.debug('GTM Checkout Update Handler Event: %o Data: %o', e, data);
    },
    /**
     * View Item
     * GTM Event: view_item
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    viewItem(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        let productData = {};

        // Convert a single product to an array
        if (!data.items) {
            productData.items = [data];
        } else {
            productData = data;
        }

        sendEcommerceEvent('view_item', productData);
    },
    /**
     * View Item List
     * GTM Event: view_item_list
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item_list
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    viewItemList(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('view_item_list', data);
    },
    /**
     * Select Item
     * GTM Event: select_item
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_item
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    selectItem(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('select_item', data);
    },
    /**
     * View Promotion
     * GTM Event: view_promotion
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_promotion
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    viewPromotion(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        let productData = {};

        // Convert a single product to an array
        if (!data.items) {
            productData.items = [data];
        } else {
            productData = data;
        }

        sendEcommerceEvent('view_promotion', productData);
    },
    /**
     * Select Promotion
     * GTM Event: select_promotion
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_promotion
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    selectPromotion(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('select_promotion', data);
    },
    /**
     * Product Share
     * GTM Event: share
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#share
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    shareProduct(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        const dataLayerData = {
            event: 'share',
            ...data,
        };

        console.debug('DataLayer Data: %o', dataLayerData);

        dataLayer.push(dataLayerData);
    },
    /**
     * Add To Wishlist
     * GTM Event: add_to_wishlist
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_to_wishlist
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    addToWishlist(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        if (!('pid' in data)) { return; }

        const product = Products.getProductDataByPID(data.pid);

        sendEcommerceEvent('add_to_wishlist', { items: [product] });
    },
    /**
     * Login
     * GTM Event: login
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    login(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        const method = typeof data === 'string' ? data : 'Username/Password';

        const dataLayerData = {
            event: 'login',
            method,
        };

        console.debug('DataLayer Data: %o', dataLayerData);

        dataLayer.push(dataLayerData);
    },
    /**
     * Registration
     * GTM Event: sign_up
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    register(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        const method = typeof data === 'string' ? data : 'Unknown';

        const dataLayerData = {
            event: 'sign_up',
            method,
        };

        console.debug('DataLayer Data: %o', dataLayerData);
        dataLayer.push(dataLayerData);
    },
    /**
     * Begin Checkout
     * GTM Event: begin_checkout
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    beginCheckout(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('begin_checkout', data);
    },
    /**
     * Add Shipping Information
     * GTM Event: add_shipping_info
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_shipping_info
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    addShippingInfo(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('add_shipping_info', data);
    },
    /**
     * Add Payment Information
     * GTM Event: add_payment_info
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    addPaymentInfo(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('add_payment_info', data);
    },
    /**
     * Purchase
     * GTM Event: purchase
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    purchase(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        sendEcommerceEvent('purchase', data);
    },
    /**
     * Config
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/config
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    config(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);
        console.debug('DataLayer Data: %o', data);
        dataLayer.push(data);
    },
    /**
     * Search
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#search
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    search(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        const dataLayerData = {
            event: 'search',
            search_term: data,
        };

        console.debug('DataLayer Data: %o', dataLayerData);
        dataLayer.push(dataLayerData);
    },
    /**
     * Form Start
     * https://support.google.com/analytics/answer/9216061?hl=en&ref_topic=13367566&sjid=12239590794766668026-NC
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    formStart(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        const dataLayerData = {
            event: 'form_start',
            form_name: data.form_name,
            form_destination: data.form_destination,
            page_url: data.page_url,
        };

        console.debug('DataLayer Data: %o', dataLayerData);
        dataLayer.push(dataLayerData);
    },
    /**
     * Form Submit
     * https://support.google.com/analytics/answer/9216061?hl=en&ref_topic=13367566&sjid=12239590794766668026-NC
     * @param {jQuery.Event|null} e
     * @param {Object} data
     */
    formSubmit(e, data) {
        console.debug('GTM Handler Event: %o Data: %o', e, data);

        const dataLayerData = {
            event: 'form_submit',
            form_name: data.form_name,
            form_destination: data.form_destination,
            page_url: data.page_url,
        };

        console.debug('DataLayer Data: %o', dataLayerData);
        dataLayer.push(dataLayerData);
    }
};
