/* eslint-disable no-continue */
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import Handlers from './handlers';
import Products from './products';
import Page from './page';

/**
 * Determines if a node is visible to the user and not
 * already viewed by checking if the element is rendered
 * on the screen and the user has scrolled into the center
 * 50% of the element's bounding box. If the element is
 * also a slide, check it's active, otherwise overflowed
 * slides would be condidered visible.
*/
function nodeInView(node) {
    if (('viewed' in node.dataset && node.dataset.viewed === 'true')
        || (node.offsetHeight === 0 || node.offsetWidth === 0)) { return false; }
    const box = node.getBoundingClientRect();

    const offsetY = box.height > window.innerHeight
        ? box.height - window.innerHeight / 4
        : box.height - box.height / 4;
    const topY = box.bottom - offsetY;
    const bottomY = box.top + offsetY;

    let inView = (bottomY >= 0
        && topY <= window.innerHeight);

    if (inView && (
        ($(node).parents('.slick-slide').length
            && !$(node).parents('.slick-slide.slick-active').length)
        || ($(node).parents('.list-element').length
            && !$(node).parents('.list-element.flex-active-slide').length))) {
        inView = false;
    }

    return inView;
}

function contentInView() {
    const productNodesOnPage = Products.getKnownNodes();

    for (let i = 0; i < productNodesOnPage.length; i += 1) {
        const node = productNodesOnPage[i];
        const inView = nodeInView(node);

        if (inView) {
            $('body').trigger('product:view', { element: node });
            node.dataset.viewed = true;
        }
    }
}

function registerViewportProductListeners() {
    document.addEventListener('scroll', throttle(contentInView, 250));
    document.addEventListener('resize', throttle(contentInView, 2000));
}

function triggerLoyaltyRegistrationEvent() {
    window.dataLayer.push({ event: 'loyalty_registration' });
}

/**
 * Registers event listeners to page elements (link/button click, etc.)
 * which trigger events registered in registerGTMListeners()
 */
function registerDOMListeners() {
    // Select Product
    // eslint-disable-next-line func-names
    $('body').on('click', '[data-gtm-product].product a', function () {
        if ($(this).closest('.swatches').length || $(this).parents('#saveDialog').length) {
            return;
        }

        const dataLayerData = Products.getDataLayerDataByChildElement(this);

        $('body').trigger('product:selected', dataLayerData);
    });

    // Select Promotion
    // eslint-disable-next-line func-names
    $('body').on('click', '.product-promotion a', function () {
        const dataLayerData = Products.getDataLayerDataByChildElement(this);
        $('body').trigger('promotion:selected', dataLayerData);
    });

    // Social Share
    // eslint-disable-next-line func-names
    $('body').on('click', '.social-icons a, #copy-share-link', function () {
        const social = $(this).data('share') ? $(this).data('share') : 'link';
        const product = $(this).parents('[data-gtm-product]').get(0);
        const productData = Products.getProductData(product);
        $('body').trigger('product:share', { method: social, item_id: productData.item_id });
    });

    // OAuth login
    // eslint-disable-next-line func-names
    $('body').on('click', 'form.login-oauth a', function () {
        if ($(this).hasClass('oauth-google')) {
            $('body').trigger('login:oauth', 'Google');
        }
    });

    // Product in view onload, scroll, resize, product slide change
    if ($('.product-slider, .flexslider').length) {
        const aggregateInit = debounce(contentInView, 50);
        $('.product-slider').on('init', aggregateInit);
        $('.product-slider').on('afterChange', contentInView);
        $('body').on('flexslider:start', aggregateInit);
        $('body').on('flexslider:after', contentInView);
        registerViewportProductListeners();
    } else {
        registerViewportProductListeners();
        contentInView();
    }

    // Loyalty Program Registration Source Tracking
    $('#loyalty-consent').parents('form').on('submit', () => {
        if ($('#loyalty-consent').is(':checked')) triggerLoyaltyRegistrationEvent();
    });

    $('.account-card-body form[name=loyalty-enrollment-form]').on('submit', () => {
        triggerLoyaltyRegistrationEvent();
    });
}

/**
 * Trigger GTM events by matching a page's
 * 'Controller-Action'
 */
function triggerPageViews() {
    if (typeof Page.action === 'string') {
        if (Page.action === 'Cart-Show') {
            $('body').trigger('cart:view', window.gtmData);
        }

        if (Page.action === 'Checkout-Begin') {
            $('body').trigger('checkout:begin', window.gtmData);
        }

        if (Page.action === 'Order-Confirm' || Page.action === 'Flow-ConfirmHostedCheckout' || Page.action === 'COPlaceOrder-Submit') {
            $('body').trigger('checkout:purchase', window.gtmData);
        }
    }
}

/**
 * Triggers view_item/view_item_list/view_promotion events
 * from a list of product nodes
 * @param {Array} eventQueue
 */
function triggerProductView(eventQueue) {
    const nodes = eventQueue.map((item) => item.data.element);

    const products = Products.getItemData(nodes);
    const listProducts = Products.getItemListData(nodes);

    products.forEach((product) => {
        if ('promotion_id' in product) {
            Handlers.viewPromotion(null, product);
        } else {
            Handlers.viewItem(null, product);
        }
    });

    Object.keys(listProducts).forEach((list) => {
        const productList = listProducts[list];
        const isPromotion = productList.items.find((item) => 'promotion_id' in item);

        if (isPromotion) {
            Handlers.viewPromotion(null, listProducts[list]);
        } else {
            Handlers.viewItemList(null, listProducts[list]);
        }
    });

    return eventQueue;
}

class DebouncedEventQueue {
    constructor(fn) {
        this.fn = fn;
        this.debounced = debounce(this.take, 20);
        this.eventQueue = [];
    }

    enqueueEvent(e, data) {
        this.eventQueue.push({ e, data });
        this.debounced();
    }

    take() {
        const taken = this.fn(this.eventQueue.slice());
        this.eventQueue.splice(0, taken.length);
    }
}

/**
 * Register GTM event handlers to SFRA 'Event Bus' events
 * $('body').on('some:event' Handlers.someEvent) or
 * thrown custom events like $('body').trigger('cart:view', data)
 */
function registerGTMListeners() {
    $('body').on('product:beforeAddToCart', Handlers.addAttribution);
    $('body').on('product:afterAddToCart', Handlers.addToCart);
    $('body').on('product:afterRemoveFromCart', Handlers.removeFromCart);

    $('body').on('product:selected', Handlers.selectItem);

    // Search/Product Loaded by AJAX
    $('body').on('product:moreloaded', () => {
        Products.updateProductNodes();
    });

    $('body').on('yotpo:refresh', () => {
        Products.updateProductNodes();
    });

    $('body').on('product:quickView', () => {
        Products.updateProductNodes();
        contentInView();
    });

    $('body').on('product:afterAttributeSelect', (e, data) => {
        if ('gtmData' in data.data && data.container.hasClass('product')) {
            const variantData = data.data.gtmData.items[0];
            const node = data.container.get(0);
            node.dataset.viewed = false;
            node.dataset.gtmProduct = JSON.stringify(variantData);
            contentInView();
        }
    });

    $('body').on('product:share', Handlers.shareProduct);

    const productEventQueue = new DebouncedEventQueue(triggerProductView);
    $('body').on('product:view', (e, data) => productEventQueue.enqueueEvent(e, data));
    $('body').on('promotion:selected', Handlers.selectPromotion);

    $('body').on('product:afterAddToWishlist', Handlers.addToWishlist);

    $('body').on('cart:view', Handlers.viewCart);

    $('form.login').on('login:success', Handlers.login);
    $('body').on('login:oauth', Handlers.login);
    $('body').on('login:register', Handlers.register);

    $('body').on('checkout:begin', Handlers.beginCheckout);

    $('body').on('checkout:addShippingInfo', Handlers.addShippingInfo);

    $('body').on('checkout:addPaymentInfo', Handlers.addPaymentInfo);

    $('body').on('checkout:purchase', Handlers.purchase);

    $('body').on('page:config', Handlers.config);
    $('body').on('search:suggestions', Handlers.search);
    $('body').on('form:start', Handlers.formStart);
    $('body').on('form:submit', Handlers.formSubmit);
}

/**
 * Gets properties from the window gtmData to send for page config
 */
function triggerConfig() {
    const configProperties = ['user_id', 'userEmail', 'country_code', 'country_name', 'language_code', 'language_name'];

    const dataLayerData = {};

    if (window.gtmData) {
        configProperties.forEach((key) => {
            if (window.gtmData[key]) {
                dataLayerData[key] = window.gtmData[key];
            }
        });
    }

    $('body').trigger('page:config', dataLayerData);
}

/**
 * Setup the GTM DataLayer on the DOMContentLoaded event
 */
function initGTM() {
    console.info('🏷 Google Tag Manager initGTM()');

    if (typeof window.dataLayer === 'undefined') {
        console.warn('🏷 Google Tag Manager DataLayer missing');
        return;
    }

    registerGTMListeners();
    registerDOMListeners();

    console.debug('Window GTM Data: %o', window.gtmData);

    triggerConfig();
    triggerPageViews();
}

document.addEventListener('DOMContentLoaded', initGTM);
