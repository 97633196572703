
import difference from 'lodash/difference';
import find from 'lodash/find';
import filter from 'lodash/filter';

// NodeList
let productNodes = [];
let listNodes = [];

const selectorListMap = {
    '.cart-wishlist': {
        name: 'Saved Products',
        type: 'Product List',
    },
    '.cart-recommender': {
        name: 'Recommended',
        type: 'Carousel',
    },
    '.wishlist': {
        name: 'Wishlist',
        type: 'Product Grid',
    },
    '.recommended-accessories-carousel': {
        name: 'Accessories',
        type: 'Carousel',
    },
    '.recommended-product-carousel': {
        name: 'Recommended',
        type: 'Carousel',
    },
    '.product-comparison': {
        name: 'Comparison',
        type: 'Product Grid',
    },
    '.search-results': {
        type: 'Product Grid',
    },
    '.product-slider': {
        name: 'Recommended',
        type: 'Product Carousel',
    },
    '.product-card-container': {
        name: 'Experience',
        type: 'Product Card Carousel',
    },
    '.herobanner-container': {
        name: 'Hero Banner',
        type: 'Carousel',
    },
};

const gtmProductSelector = '[data-gtm-product]';

const hashText = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i += 1) {
        const chr = text.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // eslint-disable-next-line no-bitwise
        hash |= 0;
    }
    return Math.abs(hash).toString(16);
};

const Products = {
    createListIdentityData(selector, idx) {
        const selectorData = selectorListMap[selector];
        const pageType = window.gtmData.pageType || 'SP';
        const name = selectorData.name || window.gtmData.category;
        const listName = `${pageType} > ${selectorData.type} > ${name}`;
        const { pathname } = window.location;
        const listId = hashText(`${pathname}${listName}${idx}`);
        return {
            item_list_id: listId,
            item_list_name: listName,
        };
    },
    /**
     * Finds the first product data object where
     * product data property item_variant equals pid
     * @param {string} pid
     * @returns {Object[]} [data-gtm-product]
     */
    getProductDataByPID(pid) {
        return find(Products.getAllProductData(), ['item_variant', pid]);
    },
    /**
     * Gets all the data from all products in productNodes
     * @returns {Object[]} [data-gtm-product]
     */
    getAllProductData() {
        return productNodes.map((node) => Products.getProductData(node));
    },
    /**
     * Gets dataset.gtmProduct from the Node
     * @param {Node} productNode
     * @returns {Object} [data-gtm-product]
     */
    getProductData(productNode) {
        return JSON.parse(productNode.dataset.gtmProduct);
    },
    /**
     * Sets dataset.gtmProduct on the Node
     * @param {Node} productNode
     */
    setProductData(productNode, data) {
        // eslint-disable-next-line no-param-reassign
        productNode.dataset.gtmProduct = JSON.stringify(data);
    },
    /**
     * Gets dataset.gtmProductList from the Node
     * @param {Node} productNode
     * @returns {Object} [data-gtm-product-list]
     */
    getProductListData(productNode) {
        return productNode.dataset.gtmProductList
            ? JSON.parse(productNode.dataset.gtmProductList)
            : {};
    },
    /**
     * Sets dataset.gtmProductList on the Node
     * @param {Node} productNode
     */
    setProductListData(productNode, data) {
        // eslint-disable-next-line no-param-reassign
        productNode.dataset.gtmProductList = JSON.stringify(data);
    },
    /**
     * Gets dataset.gtmProduct from the Node
     * @param {Node} productNode
     * @returns {Object} [data-gtm-promotion]
     */
    getPromotionData(productNode) {
        return JSON.parse(productNode.dataset.gtmPromotion);
    },
    /**
     * Sets gtm-product-list on product nodes within product lists
     * registered by selectorListMap. Called by the constructor. Call
     * this when a new product list is added after the constructor is
     * called.
     */
    updateListNodes() {
        const productListSelector = Object.keys(selectorListMap).join(', ');
        listNodes = Array.from(document.querySelectorAll(productListSelector));
        listNodes.forEach((element, idx) => {
            Object.keys(selectorListMap).forEach((selector) => {
                if (element.classList.contains(selector.slice(1))) {
                    const identityData = Products.createListIdentityData(selector, idx);
                    Products.setProductListData(element, identityData);
                }
            });
        });
    },
    /**
     * Call this once when new products are loaded onto the page or
     * on page load. Returns an array with the new product nodes added
     * to the page. Adds the product's index within
     * a list to data-gtm-product.
     * @returns {NodeList[]}
     */
    updateProductNodes() {
        const allNodes = Products.getProductNodesOnPage();
        Products.addExternalProductData(allNodes);
        const newNodes = difference(allNodes, productNodes);
        productNodes = allNodes;
        // Label the nodes with list
        listNodes.forEach((list) => {
            const listData = list.dataset.gtmProductList;
            // Products in the list
            const listProducts = Array.from(list.querySelectorAll(gtmProductSelector));
            newNodes.forEach((element) => {
                if (list.contains(element)) {
                    // eslint-disable-next-line no-param-reassign
                    element.dataset.gtmProductList = listData;
                    let idx = listProducts.findIndex((e) => e.isEqualNode(element));
                    if (idx >= 0) {
                        // eslint-disable-next-line no-param-reassign
                        element.dataset.gtmProductList = listData;
                        const gtmProduct = Products.getProductData(element);
                        if (gtmProduct) {
                            // Use the Slick index otherwise to match what's displayed
                            if ($(element).closest('[data-slick-index]').length) {
                                idx = $(element).closest('[data-slick-index]').data('slickIndex');
                            }
                            // Flex slider
                            if ($(element).closest('.flexslider').length) {
                                // eslint-disable-next-line prefer-destructuring
                                const match = $(element).closest('.flexslider').find('.list-element').toArray()
                                    .findIndex((s) => $.contains(s, element));
                                if (match >= 0) {
                                    idx = match;
                                }
                            }
                            gtmProduct.index = idx + 1;
                            Products.setProductData(element, gtmProduct);
                        }
                    }
                }
            });
        });
        // Label the nodes with promotion if set
        productNodes.forEach((node) => {
            const promotion = $(node).parents('[data-gtm-promotion]');
            if (promotion.length) {
                const promoElement = promotion.get(0);
                const gtmPromotion = Products.getPromotionData(promoElement);
                const gtmProduct = Products.getProductData(node);
                const gtmProductList = Products.getProductListData(node);
                if (gtmProduct) {
                    gtmProduct.promotion_id = gtmPromotion.id;
                    gtmProduct.promotion_name = gtmPromotion.name;
                    // Move the product list data to the item level
                    if (gtmProductList) {
                        Object.assign(gtmProduct, gtmProductList);
                        Products.setProductListData(node, {});
                    }
                    Products.setProductData(node, gtmProduct);
                    node.classList.add('product-promotion');
                }
            }
        });

        return newNodes;
    },
    /**
     * Add additional product reporting data only available on the webpage such as Yotpo ratings
     * @returns {NodeList[]}
     */
    addExternalProductData(nodes) {
        if (typeof window.yotpo !== 'undefined') {
            window.yotpo.allWidgetsReadyCallback.push(() => {
                nodes.forEach((node) => {
                    const yotpo = $(node).find('#yotpo-bottomline-top-div');
                    if (yotpo.length) {
                        const productRating = yotpo.find('.yotpo-stars span.sr-only').text().match(/\d+\.?\d*/);
                        const productReviews = yotpo.find('.yotpo-bottomline a').text().match(/\d+/);
                        if (productRating && productReviews) {
                            const gtmProduct = Products.getProductData(node);
                            [gtmProduct.item_reviews_rating] = productRating;
                            [gtmProduct.item_reviews_nbr] = productReviews;
                            Products.setProductData(node, gtmProduct);
                        }
                    }
                });
            });
        }
    },
    /**
     * Queries the document for elements with the data-gtm-product data attribute
     * @returns {NodeList[]}
     */
    getProductNodesOnPage() {
        return Array.from(document.querySelectorAll(gtmProductSelector));
    },
    /**
     *
     * @param {NodeList[] | null} productNodes
     * @returns {Object[]} [GTM Product Data]
     */
    getItemData(nodes) {
        const filteredNodes = filter(nodes || productNodes, (n) => !n.dataset.gtmProductList);
        return filteredNodes.map((n) => Products.getProductData(n));
    },
    /**
     *
     * @param {NodeList[] | null} productNodes
     * @returns {Object[]} [GTM Product Data]
     */
    getItemListData(nodes) {
        const filteredNodes = filter(nodes || productNodes, (n) => n.dataset.gtmProductList);

        const listData = {};

        filteredNodes.forEach((n) => {
            const data = Products.getProductData(n);
            const productListData = Products.getProductListData(n);

            if (!listData[productListData.item_list_id]) {
                listData[productListData.item_list_id] = {
                    ...productListData,
                    items: [],
                };
            }

            listData[productListData.item_list_id].items.push(data);
        });

        return listData;
    },
    /**
     * Return all the know Product Nodes
     * set by updateProductNodes()
     * @returns {NodeList[]}
     */
    getKnownNodes() {
        return productNodes;
    },
    getDataLayerDataByChildElement(element) {
        const productElement = $(element).parents('[data-gtm-product]');
        const productData = Products.getProductData(productElement.get(0));
        const productListData = Products.getProductListData(productElement.get(0));
        const combinedData = { ...productData, ...productListData };
        const dataLayerData = {
            items: [combinedData],
        };

        return dataLayerData;
    },
};

Products.updateListNodes();
Products.updateProductNodes();

export default Products;
